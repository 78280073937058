import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TrainingAndWorkshopsView from '../views/SubPages/TrainingAndWorkshopsView.vue'
import StrategicPlanningAndBusinessServicesView from '../views/SubPages/StrategicPlanningAndBusinessServicesView.vue'
import ProgramReviewsAndAccreditationPreparationView from '../views/SubPages/ProgramReviewsAndAccreditationPreparationView.vue'
import InstructionalDesignView from '../views/SubPages/InstructionalDesignView.vue'
import ExcellenceAndQualityAssuranceView from '../views/SubPages/ExcellenceAndQualityAssuranceView.vue'
import CourseAndProgramDevelopmentView from '../views/SubPages/CourseAndProgramDevelopmentView.vue'
import FaqPageView from '../views/FaqPageView.vue'
import DataIntegrationsView from '../views/SubPages/DataIntegrationsView.vue'
import ProfessionalServicesView from '../views/SubPages/ProfessionalServicesView.vue'
import ClientPerksOverviewView from '../views/SubPages/ClientPerksOverviewView.vue'
import JoinOurTeamView from '../views/SubPages/JoinOurTeamView.vue'
import SupportPageView from '../views/SubPages/SupportPageView.vue'
import ConferencesPageView from '../views/SubPages/ConferencesPageView.vue'
import CurrentOpeningsView from '../views/SubPages/CurrentOpeningsView.vue'
import OurStoryView from '../views/SubPages/OurStoryView.vue'
import CareersPageView from '../views/SubPages/CareersPageView.vue'
import PrivacyPageView from '../views/SubPages/PrivacyPageView.vue'
import LegalNoticeView from '../views/SubPages/LegalNoticeView.vue'
import AccessabilityPageView from '../views/SubPages/AccessabilityPageView.vue'
import AboutUsPageView from '../views/SubPages/AboutUsView.vue'


const routes = [
  {
    path: '/',
    name: '',
    component: HomeView
  },
  {
    path: '/:langCode',
    name: '',
    component: HomeView
  },
  {
    path: '/:langCode/TrainingAndWorkshops',
    name: 'TrainingAndWorkshops',
    component: TrainingAndWorkshopsView
  },
  {
    path: '/:langCode/StrategicPlanningAndBusinessServices',
    name: 'StrategicPlanningAndBusinessServices',
    component: StrategicPlanningAndBusinessServicesView
  },
  {
    path: '/:langCode/ProgramReviewsAndAccreditationPreparation',
    name: 'ProgramReviewsAndAccreditationPreparation',
    component: ProgramReviewsAndAccreditationPreparationView
  },
  {
    path: '/:langCode/InstructionalDesign',
    name: 'InstructionalDesign',
    component: InstructionalDesignView
  },
  {
    path: '/:langCode/ExcellenceAndQualityAssurance',
    name: 'ExcellenceAndQualityAssurance',
    component: ExcellenceAndQualityAssuranceView
  },
  {
    path: '/:langCode/CourseAndProgramDevelopment',
    name: 'CourseAndProgramDevelopment',
    component: CourseAndProgramDevelopmentView
  },
  {
    path: '/:langCode/FaqPage',
    name: 'FaqPage',
    component: FaqPageView
  },
  {
    path: '/404',
    name: '404',
    component: HomeView
  },
  {
    path: '/:langCode/DataIntegrations',
    name: 'DataIntegrations',
    component: DataIntegrationsView
  },
  {
    path: '/:langCode/ProfessionalServices',
    name: 'ProfessionalServicesView',
    component: ProfessionalServicesView
  },
  {
    path: '/:langCode/ClientPerksOverview',
    name: 'ClientPerksOverviewView',
    component: ClientPerksOverviewView
  },
  {
    path: '/:langCode/JoinOurTeam',
    name: 'JoinOurTeamView',
    component: JoinOurTeamView
  },
  {
    path: '/:langCode/SupportPage',
    name: 'SupportPageView',
    component: SupportPageView
  },
  {
    path: '/:langCode/ConferencesPage',
    name: 'ConferencesPageView',
    component: ConferencesPageView
  },
  {
    path: '/:langCode/CurrentOpenings',
    name: 'CurrentOpeningsView',
    component: CurrentOpeningsView
  },
  {
    path: '/:langCode/OurStory',
    name: 'OurStoryView',
    component: OurStoryView
  },
  {
    path: '/:langCode/CareersPage',
    name: 'CareersPageView',
    component: CareersPageView
  },
  {
    path: '/:langCode/PrivacyPage',
    name: 'PrivacyPageView',
    component: PrivacyPageView
  },
  {
    path: '/:langCode/LegalNotice',
    name: 'LegalNoticeView',
    component: LegalNoticeView
  },
  {
    path: '/:langCode/AccessabilityPage',
    name: 'AccessabilityPageView',
    component: AccessabilityPageView
  },
  {
    path: '/:langCode/AboutUs',
    name: 'AboutUsPageView',
    component: AboutUsPageView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active"
})




export default router
