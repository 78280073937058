<template>
    <component :placeHolder="placeHolder" :is="optionComponent" />
  </template> 
    
  <script>
  import $ from 'jquery'
  import { defineAsyncComponent } from "vue";
  const CareersPage = defineAsyncComponent(() =>
    import("../../components/SubPages/CareersPage.vue")
  );
  
  export default {
    data() {
      return {
        placeHolder: "",
      };
    },
    components: {},
    computed: {
      optionComponent() {
        return CareersPage;
      },
    },
    mounted() {
      $(`#header-logo`).addClass(`hidden`);
      this.scrollToTop();
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0, 0);
        $(`#header-logo`).addClass(`hidden`);
      }
    },
  };
  </script>
    
  <style>
  
  </style>